import { createGlobalStyle } from "../../components/styled";

const RifficFreeBold = require("./RifficFree-Bold.ttf");

const RifficFreeFontStyle = createGlobalStyle`
  /* Bold */

  @font-face {
    font-family: 'RifficFree';
    font-style: normal;
    font-weight: 700;
    src: local('RifficFree Bold'), local('RifficFree-Bold'), url(${RifficFreeBold});
  }
`;

export default RifficFreeFontStyle;
