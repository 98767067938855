/**
 * NavBar mobile/desktop menu.
 * Initially followed tutorial "Gatsby Project" by "Coding Addict" on Youtube.
 */

import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { Component, ReactNode } from "react";
import { FaBars } from "react-icons/fa";
import styled from "./styled";
import { UniversalLink } from "./utils";

const MainNav = styled.nav`
	background: #372a79;
	color: #ffffff;
	/* padding: 0 0.5rem; */
`;

const NavbarContent = styled.div`
	@media (min-width: 742px) {
		display: flex;
		height: ${(p) => (p.theme.sizes || {}).navbarHeight || "3em"};
		justify-content: center;
		/* align-items: center; */
		/* max-width: 2560px; */
		/* margin-left: auto;
      margin-right: auto; */
		/* width: 100%; */
	}
`;

class Navbar extends Component {
	state = {
		isOpen: false,
	};

	handleToggleOpen = () => {
		this.setState(() => {
			return { isOpen: !this.state.isOpen };
		});
	};

	render(): ReactNode {
		return (
			<MainNav id="mainNav">
				<NavbarContent id="navbarContent">
					<NavbarLogo handleToggleOpen={this.handleToggleOpen} />
					<NavbarLinks isOpen={this.state.isOpen} />
				</NavbarContent>
			</MainNav>
		);
	}
}

const NavbarLogoContainer = styled.div`
	padding: 0 1em;
	display: flex;
	align-items: center;
	height: ${(p) => (p.theme.sizes || {}).navbarHeight || "3em"};
	justify-content: space-between;
	vertical-align: middle;
`;

const BarsIcon = styled(FaBars)`
	font-size: 1.75rem;
	cursor: pointer;
	display: inline;

	@media (min-width: 742px) {
		display: none;
	}
`;

interface NavbarLogoProps {
	readonly handleToggleOpen: () => void;
}

const NavbarLogo: React.SFC<NavbarLogoProps> = ({ handleToggleOpen }) => (
	<StaticQuery
		query={graphql`
			query {
				logo: file(relativePath: { eq: "galoxee-logo-349x109.png" }) {
					childImageSharp {
						fixed(width: 105, height: 30) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		`}
		render={(data) => (
			<NavbarLogoContainer id="navbarLogo">
				<UniversalLink href="/">
					<Img fixed={data.logo.childImageSharp.fixed} alt="Galoxee" />
				</UniversalLink>
				<BarsIcon onClick={() => handleToggleOpen()} />
			</NavbarLogoContainer>
		)}
	/>
);

interface NavbarLinksContainerProps {
	readonly isOpen: boolean;
}

const NavbarLinksContainer = styled.ul<NavbarLinksContainerProps>`
	list-style-type: none;
	margin: 0;
	/* transition: var(--galoxeeTransition); */
	transition: all 0.5s ease-in-out;
	overflow: hidden;
	height: ${(p) => (p.isOpen ? "auto" : "0")};

	@media (min-width: 742px) {
		display: flex;
		height: auto;

		li {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

const NavbarLink = styled(UniversalLink)`
	color: white;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1em 1rem;
	font-weight: bold;
	text-decoration: none;
	transition: all 0.5s linear;
	text-align: right;
	line-height: 1em;

	@media (min-width: 742px) {
		height: ${(p) => (p.theme.sizes || {}).navbarHeight || "3em"};
	}

	&:hover {
		background-color: ${(p) => (p.theme.colors || {}).galoxeeYellow || "#e7c32f"};
		color: black;
	}
`;

const LINKS = [
	{
		text: "Start",
		href: "/",
	},
	{
		text: "ABC-Magnete",
		href: "/abc-magnete",
	},
	// {
	// 	text: "Gratis Spiele",
	// 	href: "/free-games",
	// },
	{
		text: "Shop",
		href: "https://shop.galoxee.de/",
		newWindow: true,
	},
	{
		text: "Autismus",
		href: "https://coaching.galoxee.com/autismus/wissen",
	},
];

interface NavbarLinksProps {
	readonly isOpen: boolean;
}

const NavbarLinks: React.SFC<NavbarLinksProps> = ({ isOpen }) => (
	<NavbarLinksContainer id="navbarLinksUl" isOpen={isOpen}>
		{LINKS.map((link, i) => (
			<li key={i}>
				<NavbarLink href={link.href} target={link.newWindow ? "_blank" : undefined}>
					{link.text}
				</NavbarLink>
			</li>
		))}
	</NavbarLinksContainer>
);

export default Navbar;
