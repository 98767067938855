import React from "react";
import Footer from "./Footer";
import "./layout.css";
import Navbar from "./Navbar";
import GlobalStyles from "./GlobalStyles";
import { ThemeProvider, Theme } from "./styled";
import FontStyles from "../fonts";
import "@mdi/font/css/materialdesignicons.min.css"

interface LayoutProps {}

const Layout: React.SFC<LayoutProps> = ({ children }) => (
	<ThemeProvider theme={Theme}>
		<>
			<FontStyles />
			<GlobalStyles />
			<Navbar />
			<main id="main">{children}</main>
			<Footer />
		</>
	</ThemeProvider>
);

export default Layout;
