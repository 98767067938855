import { Link } from "gatsby";
import React from "react";
import "./layout.css";

const Footer = () => (
	<footer id="main-footer" className="grid">
		<div>
			<ul>
				<li>
					<Link to="/impressum">Impressum</Link>
				</li>
				<li>
					<Link to="/agb">AGB</Link>
				</li>
				<li>
					<Link to="/wiederrufsbelehrung">Wiederrufsbelehrung</Link>
				</li>
				<li>
					<Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>
				</li>
			</ul>
			<p>Copyright © {new Date().getFullYear()} Lokodin GmbH. Alle Rechte vorbehalten.</p>
			<p style={{ marginBottom: 0, paddingBottom: ".7em" }}>
				Preisangaben inklusive MwSt aber zzgl. eventuelle Amazon Versandkosten
			</p>
		</div>
	</footer>
);

export default Footer;
