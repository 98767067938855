import React from "react";
import { Link } from "gatsby";

interface UniversalLinkProps {
	readonly href: string;
	readonly className?: string;
	readonly target?: string;
}

export const UniversalLink: React.SFC<UniversalLinkProps> = ({ href, ...otherProps }) => {
	const internal = /^\/(?!\/)/.test(href);

	if (internal) {
		return <Link to={href} activeClassName="link-active" {...otherProps} />;
	} else {
		return <a href={href} {...otherProps} />;
	}
};
