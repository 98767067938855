import * as styledComponents from "styled-components";

export const Theme = {
	colors: {
		galoxeeBlue: "#372a78",
		galoxeeRed: "#d42b3e",
		galoxeeYellow: "#e7c32f",
		galoxeeLightGray: "#cccccc",
		galoxeeOrange: "#e16a00",
	},
	sizes: {
		navbarHeight: "3em",
	},
};

const {
	default: styled,
	css,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<typeof Theme>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
