import React from "react";
import MerriweatherFontStyle from "./Merriweather";
import RifficFreeFontStyle from "./RIfficFree";
import RobotoFontStyle from "./Roboto";

const FontStyles = () => {
	return (
		<>
			<MerriweatherFontStyle />
			<RifficFreeFontStyle />
			<RobotoFontStyle />
		</>
	);
};

export default FontStyles;
