import { createGlobalStyle } from "./styled";

const GlobalStyles = createGlobalStyle`
  /* Variables. DO NOT USE THIS in further styling. Use theme instead.
     This is for compatibility only, and should be removed in future. */

  :root {
    --galoxeeBlue: ${p => p.theme.colors.galoxeeBlue};
    --galoxeeRed: ${p => p.theme.colors.galoxeeRed};
    --galoxeeYellow: ${p => p.theme.colors.galoxeeYellow};
    --galoxeeLightGray: ${p => p.theme.colors.galoxeeLightGray};
    --galoxeeOrange: ${p => p.theme.colors.galoxeeOrange};
  }

  /* Core Styles */

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: Roboto, Arial, Helvetica, sans-serif;
    /* background: #372a79; */
    color: #000;
    min-width: 320px;

  }

  h1,
  h2,
  h3 {
    font-family: Merriweather, "Times New Roman", Times, serif;
    margin: 0;
    padding: 1em 0;
  }

  p {
    margin: 1em 0;
    padding: 0;
  }

  .btn {
    display: inline-block;
    text-decoration: none;
    padding: 1em 2em;
    border: 1px solid #666;
    margin: 0.5em 0;
  }

  .btn:hover {
    background: #eee;
  }

  /* Media queries */

  @media (min-width: 742px) {
    /* My stuff */
    main {
      max-width: 2560px;
      margin-left: auto;
      margin-right: auto;
    }

    /* Flex stuff */

    .rowWrapper {
      width: 100%;
      margin: 0 auto;
    }

    .row {
      display: flex;
      max-width: 50em;
      width: 100%;
      margin: 0 auto;
    }

    .flexLeft50 {
      width: 50%;
      order: 1;
    }

    .flexRight50 {
      width: 50%;
      order: 2;
    }

    /* Other */

    .grid {
      display: grid;
      grid-template-columns: 1fr repeat(2, minmax(auto, 25em)) 1fr;
      /* grid-template-columns: 1fr repeat(2, minmax(auto, 25em)) 1fr; */
    }

    #main-footer div {
      grid-column: 2/4;
    }

    /* .content-wrap {
      grid-column: 2/4;
    } */
  }
`;

export default GlobalStyles;
