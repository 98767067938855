import { createGlobalStyle } from "../../components/styled";

const MerriweatherLight = require("./Merriweather-Light.ttf");
const MerriweatherRegular = require("./Merriweather-Regular.ttf");
const MerriweatherBold = require("./Merriweather-Bold.ttf");

console.log(MerriweatherLight);

const MerriweatherFontStyle = createGlobalStyle`
  /* Light */

  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 300;
    src: local('Merriweather Light'), local('Merriweather-Light'), url(${MerriweatherLight});
  }

  /* Regular */

  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: local('Merriweather Regular'), local('Merriweather-Regular'), url(${MerriweatherRegular});
  }

  /* Bold */

  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: local('Merriweather Bold'), local('Merriweather-Bold'), url(${MerriweatherBold});
  }
`;

export default MerriweatherFontStyle;
