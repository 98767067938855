import { createGlobalStyle } from "../../components/styled";

const RobotoThin = require("./Roboto-Thin.ttf");
const RobotoLight = require("./Roboto-Light.ttf");
const RobotoRegular = require("./Roboto-Regular.ttf");
const RobotoBold = require("./Roboto-Bold.ttf");

const RobotoFontStyle = createGlobalStyle`
  /* Thin */

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: local('Roboto Thin'), local('Roboto-Thin'), url(${RobotoThin});
  }

  /* Light */

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url(${RobotoLight});
  }

  /* Regular */

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Regular'), local('Roboto-Regular'), url(${RobotoRegular});
  }

  /* Bold */

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(${RobotoBold});
  }
`;

export default RobotoFontStyle;
